import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    pubGetShadow,
    pubUpdateShadow
} from '../api/wqmsShadowAPI'

export const fetchPubGetShadow = createAsyncThunk(
    'wqmsShadow/fetchPubGetShadow',
    async (arg, thunkAPI) => {
        return await pubGetShadow(arg.deviceId);
    }
)

export const fetchPubUpdateShadow = createAsyncThunk(
    'wqmsShadow/fetchPubUpdateShadow',
    async (arg, thunkAPI) => {
        return await pubUpdateShadow(arg.deviceId, arg.updateDesired);
    }
)

export const wqmsShadowSlice = createSlice({
    name: 'wqmsShadow',
    initialState: {
        shadowValue: {},
        formValue: {},
        queryLoading: false,
        alertVariant: '',
        alertText: '',
        opacity: 1
    },
    reducers: {
        assignShadowValue: (state, action) => {
            state.shadowValue = action.payload;
            state.formValue = action.payload;
        },
        assignFormValue: (state, action) => {
            let id = action.payload.id
            let value = action.payload.value
            state.formValue.state.reported[id] = value;
        },
        assignFormIntervalValue: (state, action) => {
            state.formValue.state.reported.interval = action.payload.value * 60000;
        },
        assignFormSensorValue: (state, action) => {
            let sensor = action.payload.sensor
            let sensorType = action.payload.sensorType
            let value = action.payload.value
            // state.formValue.state.reported.sensors[sensor][sensorType] = Number(value);
            state.formValue.state.reported.sensors[sensor][sensorType] = value;
        },
        assignUpdateAlert: (state, action) => {
            state.alertVariant = action.payload.variant;
            state.alertText = action.payload.text;
        },
        assignOpacity: (state, action) => {
            state.opacity = action.payload;
        }
    },
    extraReducers: {
        [fetchPubGetShadow.fulfilled]: (state, action) => {
            state.opacity = 1;
        },
        [fetchPubGetShadow.pending]: (state, action) => {
            state.opacity = 0.5;
        },
        [fetchPubUpdateShadow.fulfilled]: (state, action) => {
            state.queryLoading = false;
            state.alertVariant = 'info';
            state.alertText = '修改完成';
        },
        [fetchPubUpdateShadow.pending]: (state, action) => {
            state.queryLoading = true;
            state.alertVariant = '';
            state.alertText = '';
        }
    }
});

export const {
    assignShadowValue,
    assignFormValue,
    assignFormIntervalValue,
    assignFormSensorValue,
    assignUpdateAlert,
    assignOpacity } = wqmsShadowSlice.actions;

export default wqmsShadowSlice.reducer;