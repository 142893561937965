import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    changePwd
} from '../api/wqmsAuthAPI'

export const fetchChangePwd = createAsyncThunk(
    'wqmsAuth/fetchChangePwd',
    async (arg, thunkAPI) => {
        return await changePwd(arg.oldPwd, arg.newPwd);
    }
)

export const changePwdSlice = createSlice({
    name: 'changePwd',
    initialState: {
        showModel: false,
        oldPwd: '',
        newPwd: '',
        confirmPwd: '',
        alertVariant: '',
        alertText: ''
    },
    reducers: {
        handleShowPwdModel: (state, action) => {
            state.showModel = true;
        },
        handleClosePwdModel: (state, action) => {
            state.showModel = false;
        },
        assignOldPwd: (state, action) => {
            state.oldPwd = action.payload;
        },
        assignNewPwd: (state, action) => {
            state.newPwd = action.payload;
        },
        assignConfirmPwd: (state, action) => {
            state.confirmPwd = action.payload;
        },
        assignModelAlert: (state, action) => {
            state.alertVariant = action.payload.variant;
            state.alertText = action.payload.text;
        },
    },
    extraReducers: {
        [fetchChangePwd.fulfilled]: (state, action) => {
            state.alertVariant = action.payload.variant;
            state.alertText = action.payload.text;
        },
        [fetchChangePwd.pending]: (state, action) => {
            state.alertVariant = '';
            state.alertText = '';
        }
    }
});

export const { handleShowPwdModel, handleClosePwdModel, assignOldPwd, assignNewPwd, assignConfirmPwd, assignModelAlert } = changePwdSlice.actions;

export default changePwdSlice.reducer;