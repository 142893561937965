import { API, graphqlOperation } from 'aws-amplify'
import {
    queryWQMSSensorRecordV2ProdsByDeviceIdGotTimeIndex,
    queryWQMSSensorRecordDailyByDeviceIdDateIndex
} from '../graphql/queries'

export async function querySensorRecords(deviceId, startTime, endTime) {
    const a = await API.graphql(graphqlOperation(queryWQMSSensorRecordV2ProdsByDeviceIdGotTimeIndex, {
        deviceId: deviceId, startTime: startTime, endTime: endTime
    }))
    return a.data;
}

export async function querySensorRecordDaily(deviceId, querySensor, startDate, endDate) {
    let queryDeviceSensor = deviceId + "^" + querySensor.toUpperCase();
    const a = await API.graphql(graphqlOperation(queryWQMSSensorRecordDailyByDeviceIdDateIndex, {
        deviceId: queryDeviceSensor, startDate: startDate, endDate: endDate
    }))
    return a.data;
}