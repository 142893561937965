import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import {
    querySensorRecords,
    querySensorRecordDaily
} from '../api/wqmsSensorRecordAPI'

export const fetchQuerySensorRecords = createAsyncThunk(
    'wqmsSensorRecord/fetchQuerySensorRecords',
    async (arg, thunkAPI) => {
        return await querySensorRecords(arg.deviceId, arg.startTime, arg.endTime);
    }
)
export const fetchQuerySensorRecordDaily = createAsyncThunk(
    'wqmsSensorRecord/fetchQuerySensorRecordDaily',
    async (arg, thunkAPI) => {
        return await querySensorRecordDaily(arg.deviceId, arg.querySensor, arg.startDate, arg.endDate);
    }
)

export const queryHistorySlice = createSlice({
    name: 'queryHistory',
    initialState: {
        queryType: 'full',
        querySensor: '',
        querySensorDisplay: '',
        startDate: '',
        endDate: '',
        queryLoading: false,
        alertVariant: '',
        alertText: '',
        gotTimeSeries: [],
        sensorValueSeries: [],
        sensorHiSeries: [],
        sensorLowSeries: []
    },
    reducers: {
        assignQueryType: (state, action) => {
            state.queryType = action.payload;
        },
        assignQuerySensor: (state, action) => {
            state.querySensor = action.payload;
        },
        assignStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        assignEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        assignAlert: (state, action) => {
            state.alertVariant = action.payload.variant;
            state.alertText = action.payload.text;
        },
        clearQueryData: (state, action) => {
            state.queryLoading = false;
            state.alertVariant = '';
            state.alertText = '';
            state.querySensorDisplay = '';
            state.gotTimeSeries = [];
            state.sensorValueSeries = [];
            state.sensorHiSeries = [];
            state.sensorLowSeries = [];
        }
    },
    extraReducers: {
        [fetchQuerySensorRecords.fulfilled]: (state, action) => {
            var items = action.payload.queryWQMSSensorRecordV2ProdsByDeviceIdGotTimeIndex.items;
            // console.log(items);
            var querySensor = state.querySensor;
            var gotTimeArr = [];
            var sensorValueArr = [];
            var sensorHiArr = [];
            var sensorLowArr = [];
            var sensorDisplay = ''
            for (var item of items) {
                var obj = JSON.parse(item.records);
                if (item.gotTime && obj && obj[querySensor]) {
                    sensorDisplay = obj[querySensor].name
                    gotTimeArr.push(moment(item.gotTime).format('YYYY-MM-DD HH:mm'));
                    sensorValueArr.push(obj[querySensor].value);
                    sensorHiArr.push(obj[querySensor].HI);
                    sensorLowArr.push(obj[querySensor].LOW);
                }
            }
            state.querySensorDisplay = sensorDisplay;
            state.gotTimeSeries = gotTimeArr;
            state.sensorValueSeries = sensorValueArr;
            state.sensorHiSeries = sensorHiArr;
            state.sensorLowSeries = sensorLowArr;
            state.queryLoading = false;
            if (gotTimeArr.length === 0) {
                state.alertVariant = 'warning';
                state.alertText = '查無資料';
            }
        },
        [fetchQuerySensorRecords.pending]: (state, action) => {
            state.queryLoading = true;
            state.alertVariant = '';
            state.alertText = '';
            state.querySensorDisplay = '';
            state.gotTimeSeries = [];
            state.sensorValueSeries = [];
            state.sensorHiSeries = [];
            state.sensorLowSeries = [];
        },
        [fetchQuerySensorRecordDaily.fulfilled]: (state, action) => {
            var items = action.payload.queryWQMSSensorRecordDailyByDeviceIdDateIndex.items;
            // console.log(items);
            var gotTimeArr = [];
            var sensorValueArr = [];
            var sensorHiArr = [];
            var sensorLowArr = [];
            var sensorDisplay = ''
            for (var item of items) {
                gotTimeArr.push(item.date);
                sensorValueArr.push(item.average);
                sensorHiArr.push(item.hi);
                sensorLowArr.push(item.low);
                sensorDisplay = item.sensorName;
            }
            state.querySensorDisplay = sensorDisplay;
            state.gotTimeSeries = gotTimeArr;
            state.sensorValueSeries = sensorValueArr;
            state.sensorHiSeries = sensorHiArr;
            state.sensorLowSeries = sensorLowArr;
            state.queryLoading = false;
            if (gotTimeArr.length === 0) {
                state.alertVariant = 'warning';
                state.alertText = '查無資料';
            }
        },
        [fetchQuerySensorRecordDaily.pending]: (state, action) => {
            state.queryLoading = true;
            state.alertVariant = '';
            state.alertText = '';
            state.querySensorDisplay = '';
            state.gotTimeSeries = [];
            state.sensorValueSeries = [];
            state.sensorHiSeries = [];
            state.sensorLowSeries = [];
        }
    }
});

export const { assignQueryType, assignQuerySensor, assignStartDate, assignEndDate, assignAlert, clearQueryData } = queryHistorySlice.actions;

export default queryHistorySlice.reducer;