/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryWQMSAuthProds = /* GraphQL */ `
  query QueryWQMSAuthProds($email: String!) {
    queryWQMSAuthProds(email: $email) {
      items {
        deviceId
        email
        controlLevel
        deviceName
        givenName
      }
      nextToken
    }
  }
`;
export const queryWQMSSensorRecordV2ProdsByDeviceIdGotTimeIndex = /* GraphQL */ `
  query QueryWQMSSensorRecordV2ProdsByDeviceIdGotTimeIndex(
    $deviceId: String!
    $startTime: AWSTimestamp!
    $endTime: AWSTimestamp!
    $first: Int
    $after: String
  ) {
    queryWQMSSensorRecordV2ProdsByDeviceIdGotTimeIndex(
      deviceId: $deviceId
      startTime: $startTime
      endTime: $endTime
      first: $first
      after: $after
    ) {
      items {
        deviceId
        gotTime
        deviceName
        records
        gotTimeStr
      }
      nextToken
    }
  }
`;
export const queryWQMSSensorRecordDailyByDeviceIdDateIndex = /* GraphQL */ `
  query QueryWQMSSensorRecordDailyByDeviceIdDateIndex(
    $deviceId: String!
    $startDate: String!
    $endDate: String!
    $first: Int
    $after: String
  ) {
    queryWQMSSensorRecordDailyByDeviceIdDateIndex(
      deviceId: $deviceId
      startDate: $startDate
      endDate: $endDate
      first: $first
      after: $after
    ) {
      items {
        deviceId
        date
        average
        max
        min
        dailyCount
        insertTime
        hi
        low
        sensorName
      }
      nextToken
    }
  }
`;
