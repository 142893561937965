import { Auth, API, graphqlOperation } from 'aws-amplify'
import { queryWQMSAuthProds } from '../graphql/queries'

export async function queryWQMSAuths() {
    let filterEmail = '';

    await Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
        filterEmail = user.attributes.email;
    }).catch(err => console.log(err));

    const a = await API.graphql(graphqlOperation(queryWQMSAuthProds, { email: filterEmail }))
    return a.data;
}

export async function changePwd(oldPwd, newPwd) {
    let ret = { variant: '', text: '' }

    await Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, oldPwd, newPwd);
        })
        .then(data => {
            ret = { variant: 'success', text: data }
        })
        .catch(err => {
            ret = { variant: 'danger', text: err.message }
        });

    return ret;
}