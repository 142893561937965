import React from 'react';
import ReactECharts from 'echarts-for-react';

export default function HistoryChart(props) {

    const {
        title,
        gotTimeSeries,
        sensorValueSeries,
        sensorHiSeries,
        sensorLowSeries
    } = props

    const options = {
        title: {
            text: title,
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: gotTimeSeries
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: 'value',
                data: sensorValueSeries,
                type: 'line',
                smooth: true,
                symbol: 'none'
            },
            {
                name: 'hi',
                data: sensorHiSeries,
                type: 'line',
                smooth: true,
                color: '#F4A7B9',
                symbol: 'none',
            },
            {
                name: 'low',
                data: sensorLowSeries,
                type: 'line',
                smooth: true,
                color: '#F4A7B9',
                symbol: 'none',
            }
        ]
    };

    return (
        <div style={{ marginTop: 10 }}>
            <ReactECharts option={options} />
        </div>
    )

}