import { combineReducers } from 'redux'

import wqmsAuthReducer from '../slice/wqmsAuthSlice'
import wqmsShadowReducer from '../slice/wqmsShadowSlice'
import queryHistoryReducer from '../slice/queryHistorySlice'
import changePwdReducer from '../slice/changePwdSlice'


const appReducer = combineReducers({
    wqmsAuth: wqmsAuthReducer,
    wqmsShadow: wqmsShadowReducer,
    queryHistory: queryHistoryReducer,
    changePwd: changePwdReducer
})

export default appReducer;