import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import HistoryChart from './components/HistoryChart';
import {
    addDays,
    getDayStr
} from './utils/util'
import {
    assignUpdateAlert
} from './slice/wqmsShadowSlice';
import {
    assignQueryType,
    assignQuerySensor,
    assignStartDate,
    assignEndDate,
    assignAlert,
    fetchQuerySensorRecords,
    fetchQuerySensorRecordDaily
} from './slice/queryHistorySlice';
import {
    assignModelAlert
} from './slice/changePwdSlice';

export default function HistoryData() {

    const dispatch = useDispatch();

    const selectedDevice = useSelector(state => state.reducer.wqmsAuth.selectedDevice);
    const shadowValue = useSelector(state => state.reducer.wqmsShadow.shadowValue);

    const queryType = useSelector(state => state.reducer.queryHistory.queryType);
    const querySensor = useSelector(state => state.reducer.queryHistory.querySensor);
    const querySensorDisplay = useSelector(state => state.reducer.queryHistory.querySensorDisplay);
    const startDate = useSelector(state => state.reducer.queryHistory.startDate);
    const endDate = useSelector(state => state.reducer.queryHistory.endDate);
    const queryLoading = useSelector(state => state.reducer.queryHistory.queryLoading);
    const alertVariant = useSelector(state => state.reducer.queryHistory.alertVariant);
    const alertText = useSelector(state => state.reducer.queryHistory.alertText);

    const gotTimeSeries = useSelector(state => state.reducer.queryHistory.gotTimeSeries);
    const sensorValueSeries = useSelector(state => state.reducer.queryHistory.sensorValueSeries);
    const sensorHiSeries = useSelector(state => state.reducer.queryHistory.sensorHiSeries);
    const sensorLowSeries = useSelector(state => state.reducer.queryHistory.sensorLowSeries);

    const today = new Date();
    const todayStr = getDayStr(today);
    const oneWeekBeforeStr = getDayStr(addDays(today, -6));

    useEffect(() => {
        dispatch(assignEndDate(todayStr));
        dispatch(assignStartDate(oneWeekBeforeStr));
    }, []);

    let querySensorOptions = []
    if (shadowValue.state && shadowValue.state.reported.sensors && shadowValue.state.reported.sensorValue) {
        // S1_1
        if (shadowValue.state.reported.sensors.s1_1 && shadowValue.state.reported.sensorValue.s1_1
            && shadowValue.metadata.reported.sensorValue.s1_1) {
            querySensorOptions.push(<option value="s1_1" key="s1_1">{shadowValue.state.reported.sensors.s1_1.name}</option>)
        }
        // S1_2
        if (shadowValue.state.reported.sensors.s1_2 && shadowValue.state.reported.sensorValue.s1_2
            && shadowValue.metadata.reported.sensorValue.s1_2) {
            querySensorOptions.push(<option value="s1_2" key="s1_2">{shadowValue.state.reported.sensors.s1_2.name}</option>)
        }
        // S1_3
        if (shadowValue.state.reported.sensors.s1_3 && shadowValue.state.reported.sensorValue.s1_3
            && shadowValue.metadata.reported.sensorValue.s1_3) {
            querySensorOptions.push(<option value="s1_3" key="s1_3">{shadowValue.state.reported.sensors.s1_3.name}</option>)
        }
        // S1_4
        if (shadowValue.state.reported.sensors.s1_4 && shadowValue.state.reported.sensorValue.s1_4
            && shadowValue.metadata.reported.sensorValue.s1_4) {
            querySensorOptions.push(<option value="s1_4" key="s1_4">{shadowValue.state.reported.sensors.s1_4.name}</option>)
        }
        // S1_5
        if (shadowValue.state.reported.sensors.s1_5 && shadowValue.state.reported.sensorValue.s1_5
            && shadowValue.metadata.reported.sensorValue.s1_5) {
            querySensorOptions.push(<option value="s1_5" key="s1_5">{shadowValue.state.reported.sensors.s1_5.name}</option>)
        }
        // S1_6
        if (shadowValue.state.reported.sensors.s1_6 && shadowValue.state.reported.sensorValue.s1_6
            && shadowValue.metadata.reported.sensorValue.s1_6) {
            querySensorOptions.push(<option value="s1_6" key="s1_6">{shadowValue.state.reported.sensors.s1_6.name}</option>)
        }
    }

    let alertDisplay = []
    if (alertVariant && alertText) {
        alertDisplay = (
            <Alert variant={alertVariant} onClose={() => clearAlert()} dismissible>
                {alertText}
            </Alert>
        )
    }

    const execQuery = () => {
        if (startDate && endDate && querySensor && queryType) {
            if (queryType === 'full') {
                let startTime = new Date(startDate).getTime() - 28800000;
                let endTime = new Date(endDate).getTime() + 57599999;
                if (endTime - startTime < 0 || endTime - startTime > 2764800000) {
                    dispatch(assignAlert({
                        variant: 'danger',
                        text: '查詢日期上限為30日'
                    }));
                } else {
                    dispatch(fetchQuerySensorRecords({
                        deviceId: selectedDevice.deviceId,
                        querySensor: querySensor,
                        startTime: startTime,
                        endTime: endTime
                    }));
                }
            } else if (queryType === 'daily') {
                dispatch(fetchQuerySensorRecordDaily({
                    deviceId: selectedDevice.deviceId,
                    querySensor: querySensor,
                    startDate: startDate,
                    endDate: endDate
                }));
            } else {
                dispatch(assignAlert({
                    variant: 'danger',
                    text: '請輸入查詢類別'
                }));
            }
        } else {
            dispatch(assignAlert({
                variant: 'danger',
                text: '請輸入查詢資料'
            }));
        }
    }

    const execDownloadCsv = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += 'gotTime,value\r\n';
        for (let i = 0; i < sensorValueSeries.length; i++) {
            csvContent += gotTimeSeries[i] + ',' + sensorValueSeries[i] + '\r\n';
        }
        let encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    let downloadButton = []
    if (sensorValueSeries.length > 0 && sensorValueSeries.length === gotTimeSeries.length) {
        downloadButton = (
            <Button variant="primary" onClick={execDownloadCsv}>下載 CSV</Button>
        )
    }

    function clearAlert() {
        dispatch(assignAlert({ variant: '', text: '' }));
        dispatch(assignUpdateAlert({ variant: '', text: '' }));
        dispatch(assignModelAlert({ variant: '', text: '' }));
    }

    return (
        <div>
            <div style={{ padding: 10 }}>
                {alertDisplay}
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="queryType">
                                <div>
                                    <Form.Check inline label="完整資料" name="queryType" type='radio' id='full' checked={queryType === 'full'} onChange={(event) => {
                                        dispatch(assignQueryType('full'))
                                    }} />
                                    <Form.Check inline label="日平均" name="queryType" type='radio' id='daily' checked={queryType === 'daily'} onChange={(event) => {
                                        dispatch(assignQueryType('daily'))
                                    }} />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="querySensor">
                                <Form.Label>查詢 sensor</Form.Label>
                                <Form.Control as="select" onChange={(event) => {
                                    dispatch(assignQuerySensor(event.target.value))
                                    // var index = event.nativeEvent.target.selectedIndex;
                                    // dispatch(assignQuerySensor({querySensor: event.target.value, querySensorDisplay: event.nativeEvent.target[index].text}))
                                }}>
                                    <option value="" key="">-----</option>
                                    {querySensorOptions}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Label>開始日期</Form.Label>
                            <Form.Control type="date" value={startDate} onChange={(event) => {
                                // console.log(event.target.value);
                                dispatch(assignStartDate(event.target.value))
                            }} />
                        </Col>
                        <Col>
                            <Form.Label>結束日期</Form.Label>
                            <Form.Control type="date" value={endDate} onChange={(event) => {
                                console.log(event.target.value);
                                dispatch(assignEndDate(event.target.value))
                            }} />
                        </Col>
                        <Col>
                            <div><h5>&nbsp;</h5></div>
                            <Button variant="primary" onClick={execQuery} disabled={queryLoading}>查詢</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <span style={{ color: '#999' }}>因系統效能考量，有限制回傳筆數，如果回傳筆數不完整，請縮小查詢時間區間</span>
                &nbsp;&nbsp;{downloadButton}
            </div>
            <div>
                <HistoryChart
                    title={querySensorDisplay}
                    gotTimeSeries={gotTimeSeries}
                    sensorValueSeries={sensorValueSeries}
                    sensorHiSeries={sensorHiSeries}
                    sensorLowSeries={sensorLowSeries}
                />
            </div>
        </div>
    )

}