import { PubSub } from 'aws-amplify'

export async function pubGetShadow(deviceId) {
    try {
        await PubSub.publish('$aws/things/' + deviceId + '/shadow/get', {});
    } catch (err) {
        console.log('pub topic err', err);
    }
}

export async function pubUpdateShadow(deviceId, updateDesired) {
    try {
        await PubSub.publish('$aws/things/' + deviceId + '/shadow/update', updateDesired);
    } catch (err) {
        console.log('pub topic err', err);
    }
}